const navItems = [
  {
    title: 'Home',
    link: '/',
  },
  {
    title: 'Contact',
    link: '/contact'
  },
  {
    title: 'Our EPK',
    link: '/epk'
  }
]

export default navItems