import React from 'react'

import PageLayout from './pages/PageLayout'

function App() {
  return (
    <div>
      <PageLayout/>
    </div>
  )
}

export default App;
